'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.WalletHero = function WalletHero() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $block = document.querySelector('.wallet-hero .wallet-slider');

    if ($block) {
      var flkty = new Flickity($block, {
        cellAlign: 'center',
        contain: true,
        imagesLoaded: true,
        groupCells: true
      });
    }

    var $itemTriggers = [].slice.call(document.querySelectorAll('.wallet-hero .wallet-item'));

    if ($itemTriggers.length) {
      var $container = document.querySelector('.wallet-hero .wallet-hero-popup-container');

      $itemTriggers.forEach(function ($itemTrigger) {
        $itemTrigger.addEventListener('click', function (e) {
          e.preventDefault();

          window.scrollTo(0, 0);
          var $body = document.querySelector('body');

          var selectedIndex = $itemTrigger.dataset.openWalletItem;

          $container.classList.add('is-active');
          var $popup = $container.querySelector('[data-wallet-item="' + selectedIndex + '"]');
          $popup.classList.add('is-active');
          $body.style.overflow = 'hidden';
        });
      });
    }

    var $closeTriggers = [].slice.call(document.querySelectorAll('.wallet-hero .popup-close'));

    if ($closeTriggers.length) {
      $closeTriggers.forEach(function ($closeTrigger) {
        $closeTrigger.addEventListener('click', function (e) {
          e.preventDefault();

          var $body = document.querySelector('body');

          var $container = document.querySelector('.wallet-hero .wallet-hero-popup-container');
          var $popup = $closeTrigger.closest('.wallet-hero-popup-item');

          $container.classList.remove('is-active');
          $popup.classList.remove('is-active');
          $body.style.overflow = 'auto';
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();