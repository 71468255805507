'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ScrollTo = function ScrollTo() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $links = [].slice.call(document.querySelectorAll('a'));

    if ($links.length) {
      $links.forEach(function ($link) {
        $link.addEventListener('click', function (e) {
          var url = $link.getAttribute('href');

          if (url.charAt(0) === '#') {
            e.preventDefault();

            var $destination = document.querySelector(url);

            if ($destination) {
              var $menu = document.querySelector('header');
              var position = $destination.getBoundingClientRect().top + window.scrollY - $menu.offsetHeight;
              window.scroll({
                top: parseFloat(position),
                left: 0,
                behavior: 'smooth'
              });
            }
          }
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();