'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Header = function Header() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $menu = document.querySelector('.header');

    if ($menu) {
      var checkScrollPosition = function checkScrollPosition() {
        var position = document.documentElement.scrollTop;

        if (position === 0 && $menu.classList.contains('is-fixed')) {
          $menu.classList.remove('is-fixed');
        } else if (position > 0 && !$menu.classList.contains('is-fixed')) {
          $menu.classList.add('is-fixed');
        }
      };

      window.addEventListener('scroll', function () {
        checkScrollPosition();
      });
      checkScrollPosition();

      var $mobileTrigger = $menu.querySelector('.header-mobile-trigger');

      if ($mobileTrigger) {
        $mobileTrigger.addEventListener('click', function (e) {
          e.preventDefault();

          var $mobileMenu = document.querySelector('.mobile-menu');
          var $body = document.querySelector('body');

          if ($mobileTrigger.classList.contains('is-active')) {
            $mobileTrigger.classList.remove('is-active');
            $mobileMenu.classList.remove('is-active');
            $body.style.overflow = 'auto';
          } else {
            $mobileTrigger.classList.add('is-active');
            $mobileMenu.classList.add('is-active');
            $body.style.overflow = 'hidden';
          }
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();