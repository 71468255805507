'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.HomeTeam001 = function HomeTeam001() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $block = document.querySelector('.home-team001 .team001-list');

    if ($block) {
      if (window.innerWidth <= 767) {
        swiper = new Swiper('.home-team001 .team001-list.swiper', {
          slidesPerView: "auto"
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();