'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.FooterNewsletter = function FooterNewsletter() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $newsletterForm = document.querySelector('.footer .newsletter-form');

    if ($newsletterForm) {
      var $alert = document.querySelector('.footer .newsletter-section .alert');
      var $alertContent = $alert.querySelector('p');
      var successText = $alert.textContent;

      $newsletterForm.addEventListener('submit', function (e) {
        e.preventDefault();

        $alert.classList.remove('is-active');
        $alert.classList.remove('error');
        $alert.classList.remove('success');

        var data = new FormData();
        data.append('consent', $newsletterForm.querySelector('input[name="consent"]').value);
        data.append('email', $newsletterForm.querySelector('input[name="email"]').value);
        data.append('CRAFT_CSRF_TOKEN', $newsletterForm.querySelector('input[name="CRAFT_CSRF_TOKEN"]').value);

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener('readystatechange', function () {
          if (this.readyState === 4) {
            var response = JSON.parse(this.response);
            if (response.success) {
              $alert.classList.add('is-active');
              $alert.classList.add('success');
              $alertContent.textContent = successText;
            } else {
              $alert.classList.add('is-active');
              $alert.classList.add('error');
              $alertContent.textContent = response.errors.email[0];
            }
          }
        });

        xhr.open('POST', '/actions/newsletter/newsletter/subscribe/');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.send(data);
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();