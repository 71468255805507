'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.TeamTeam001 = function TeamTeam001() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $triggers = [].slice.call(document.querySelectorAll('.team-team001 .item-bio .item-link'));

    if ($triggers.length) {
      $triggers.forEach(function ($trigger) {
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();

          var $bioContainer = $trigger.closest('.item-bio');

          if ($bioContainer) {
            $bioContainer.classList.remove('is-reduced');
          }
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();