'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.FormSuccess = function FormSuccess() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $notice = document.querySelector('.form-success');

    if ($notice) {
      $notice.classList.add('is-active');

      var $triggers = [$notice.querySelector('.form-success-layout'), $notice.querySelector('.form-success-container .success-close'), $notice.querySelector('.form-success-container .success-button')];

      $triggers.forEach(function ($trigger) {
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();

          $notice.classList.remove('is-active');
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();