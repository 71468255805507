'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.HomeEcosystem001 = function HomeEcosystem001() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $buttonsContainer = document.querySelectorAll('.home-ecosystem001 .ecosystem001-list .ecosystem001-item');
    var $targets = document.querySelectorAll('.home-services001 .services001-list .service001-item');

    if ($buttonsContainer.length && $targets.length) {
      $buttonsContainer.forEach(function ($buttonContainer, index) {
        var $button = $buttonContainer.querySelector('.item-link');

        if ($button) {
          $button.addEventListener('click', function (e) {
            e.preventDefault();

            var $target = $targets[index];
            var $header = document.querySelector('.header');
            var y = $target.getBoundingClientRect().top + window.scrollY - $header.offsetHeight - 100;
            window.scroll({
              top: y,
              behavior: 'smooth'
            });
          });
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();