'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.PopupForms = function PopupForms() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $popupForms = document.querySelectorAll('[data-popup-form]');
    var $formsTriggers = document.querySelectorAll('[data-open-form]');

    if ($formsTriggers.length) {
      $formsTriggers.forEach(function ($formTrigger) {
        $formTrigger.addEventListener('click', function (e) {
          e.preventDefault();
          var $popupForm = document.querySelector('[data-popup-form="' + $formTrigger.dataset.openForm + '"]');

          if ($popupForm) {
            console.log($popupForm);
            if ($formTrigger.dataset.projectType) {
              console.log($formTrigger.dataset.projectType);
              var projectTypeSelect = $popupForm.querySelector('select[name="message[ProjectType]"]');

              if (projectTypeSelect) {
                projectTypeSelect.options[$formTrigger.dataset.projectType].selected = 'selected';
              }
            }

            $popupForm.classList.toggle('is-active');
          }
        });
      });
    }

    if ($popupForms.length) {
      $popupForms.forEach(function ($popupForm) {
        var $closeBtn = $popupForm.querySelector('.popup-form-close');

        if ($closeBtn) {
          $closeBtn.addEventListener('click', function (e) {
            e.preventDefault();

            $popupForm.classList.toggle('is-active');
          });
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();